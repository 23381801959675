import { SharedSlugs } from '@maersk-global/apmt-dpos-common';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MapComponent } from '@/components/organisms/Map/Map';
import { reactQueryKeys } from '@/constants/queryKeys';
import { getParameterizedRoute, routes } from '@/routes/routes';
import { useMapStore } from '@/store/mapStore';
import { useTerminalStore } from '@/store/terminalStore';
export const MapEditPage = () => {
    const { t } = useTranslation();

    const { terminalId, setPageTitle } = useTerminalStore(state => ({
        setPageTitle: state.setPageTitle,
        terminalId: state.terminalId,
    }));

    const {
        quayCranesToShow: mapEditState,
        save,
        cancel,
    } = useMapStore(store => ({
        quayCranesToShow: store.editModeEnabled,
        save: store.saveEditMode,
        cancel: store.disableEditMode,
    }));
    useEffect(() => {
        setPageTitle(
            t('pages.mapEdit.pageTitle', {
                quayCranes: mapEditState ? mapEditState.quayCraneNames.join(' + ') : '',
            }),
        );
    }, [mapEditState]);
    const nav = useNavigate();
    const queryClient = useQueryClient();
    if (!mapEditState) {
        nav(getParameterizedRoute(SharedSlugs.flowOverview));
    }

    return (
        <div className="h-full">
            <MapComponent
                editMode
                confirmEdit={async coord => {
                    await save(coord, terminalId);
                    if (!mapEditState) {
                        return;
                    }
                    const queryKeys = mapEditState.quayCraneNames.map(quayCraneName => {
                        return `${reactQueryKeys.FLOW_POSITIONS}-${quayCraneName.toLowerCase()}`;
                    });
                    setTimeout(() => {
                        void queryClient.invalidateQueries(queryKeys);
                        void queryClient.refetchQueries(queryKeys);
                    }, 1000);
                    switch (mapEditState.startedFrom.startPlace) {
                        case 'FlowDetail':
                            nav(
                                routes.flowDetail(terminalId, mapEditState.startedFrom.quayCraneId),
                            );
                            break;
                        case 'ControlPage':
                            nav(routes.controlOverView(terminalId));
                    }
                }}
                cancelEdit={cancel}
                quayCranesToShow={mapEditState ? mapEditState.quayCraneNames : undefined}
            />
        </div>
    );
};
