import Keycloak, { KeycloakTokenParsed } from "keycloak-js";
import { Codec } from "purify-ts";
import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { accessTokenCodec } from "./decoders";
import { Role, User } from "./types";

export type Auth = {
    user: User;

    /**
     * Checks if token needs to be updated and if so updates and returns it.
     */
    updateToken: () => Promise<string | undefined>;

    /**
     * fetch function which makes sure the access token is not expired and
     * adds the access token as bearer token to the Authorization header.
     */
    fetchWithAuth: typeof fetch;
    logout: () => void;
};

const nonKeycloakUser: User = {
    email: "",
    name: "",
    roles: [Role.Admin],
    isAdmin: true,
    isNormal: false,
    isReadOnly: false,
};

const nonKeycloakAuth: Auth = {
    user: nonKeycloakUser,
    updateToken: async () => undefined,
    logout: () => undefined,
    fetchWithAuth: fetch,
};

const extractUser = (
    token: KeycloakTokenParsed,
    groupsDecoder: Codec<Role[]>
): User => {
    return accessTokenCodec
        .decode(token)
        .chain(({ email, given_name, family_name, groups }) =>
            groupsDecoder.decode(groups).map((decodedGroups) => ({
                email: email,
                name: `${given_name} ${family_name}`,
                roles: decodedGroups,
                isAdmin: decodedGroups.includes(Role.Admin),
                isNormal: decodedGroups.includes(Role.Normal),
                isReadOnly: decodedGroups.includes(Role.ReadOnly),
            }))
        )
        .mapLeft(
            (errMessage) =>
                new Error(
                    `Could not extract user from keycloak access token: ${errMessage}`
                )
        )
        .unsafeCoerce();
};

const fetchWithAuth: (keycloak: Keycloak) => typeof fetch =
    (keycloak) => async (input, init) => {
        await keycloak.updateToken(5);
        const headers = Object.assign({}, init?.headers, {
            Authorization: `Bearer ${keycloak.token}`,
        });
        return fetch(input, Object.assign({}, init, { headers }));
    };

const onKeycloakAuthError = (err: unknown) => {
    console.error("Auth error", err);
};

const onKeycloakAuthRefreshError = () => {
    console.error("Error refreshing token, session might have expired");
    window.location.reload();
};

const createKeycloakAuthContext = (
    keycloak: Keycloak,
    groupsDecoder: Codec<Role[]>
): Auth => {
    if (!keycloak.tokenParsed) {
        throw new Error("Missing tokenParsed in keycloak object");
    }
    return {
        user: extractUser(keycloak.tokenParsed, groupsDecoder),
        updateToken: async () => {
            await keycloak.updateToken(5);

            return keycloak.token;
        },
        fetchWithAuth: fetchWithAuth(keycloak),
        logout: () => keycloak.logout(),
    };
};

export const KeycloakAuthContext = createContext<Auth | undefined>(undefined);

export const KeycloakAuthProvider = ({
    children,
    keycloak,
    groupsDecoder,
}: PropsWithChildren<{
    keycloak?: Keycloak;
    groupsDecoder: Codec<Role[]>;
}>) => {
    const [authenticationError, setAuthenticationError] = useState<
        string | undefined
    >(undefined);
    const [authContext, setAuthContext] = useState<Auth | undefined>();

    const onKeycloakAuthRefreshSuccess = () => {
        if (keycloak && keycloak.tokenParsed) {
            setAuthContext(createKeycloakAuthContext(keycloak, groupsDecoder));
        }
    };

    useEffect(() => {
        if (keycloak) {
            keycloak.onTokenExpired = () => {
                console.info("Token expired");
            };
            keycloak.onAuthError = onKeycloakAuthError;
            keycloak.onAuthRefreshError = onKeycloakAuthRefreshError;
            keycloak.onAuthRefreshSuccess = onKeycloakAuthRefreshSuccess;

            if (!keycloak.tokenParsed || !keycloak.token) {
                setAuthenticationError("Missing access token from keycloak");
            } else {
                try {
                    setAuthContext(
                        createKeycloakAuthContext(keycloak, groupsDecoder)
                    );
                } catch (err) {
                    if (err instanceof Error) {
                        setAuthenticationError(err.message);
                    } else {
                        setAuthenticationError(
                            `Error while creating auth context (${err})`
                        );
                    }
                }
            }
        } else {
            setAuthContext(nonKeycloakAuth);
        }
    }, [keycloak]);

    if (authContext) {
        return (
            <KeycloakAuthContext.Provider value={authContext}>
                {children}
            </KeycloakAuthContext.Provider>
        );
    } else if (authenticationError) {
        return (
            <div>
                <h2>Failed to authenticate</h2>
                <span>{authenticationError}</span>
            </div>
        );
    } else {
        return <>Loading..</>;
    }
};

export const useAuth = (): Auth => {
    const auth = useContext(KeycloakAuthContext);
    if (!auth) {
        throw new Error("Missing Auth context");
    }
    return auth;
};
