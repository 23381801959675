import { Maybe } from 'purify-ts';
import { Marker } from 'react-map-gl/maplibre';

import {
    InaccurateSignalTruckMarker,
    StaleSignalTruckMarker,
    TruckMarker,
} from '@/components/atoms/TruckMarker/TruckMarker';
import { TruckMapColors, TruckMapShapes } from '@/constants/enums';
import { TruckMapData, useControlPageStore, useTruckPositionsStore } from '@/store';
import { useYardStore } from '@/store/yardStore';
type Truck = {
    truckName: string;
};
export const TruckMarkersForRtg = ({
    cheId,
    onTruckClicked,
    shape,
}: {
    cheId: string;
    onTruckClicked: (truckId: string) => void;
    shape: TruckMapShapes;
}) => {
    const { userFilteredEquipmentOnMapPerQuayCrane } = useControlPageStore(state => ({
        userFilteredEquipmentOnMapPerQuayCrane: state.userFilteredEquipmentOnMapPerQuayCrane,
    }));
    const truckPositions = useTruckPositionsStore(state => state.truckPositions);
    const yardWork = useYardStore(store => store.yardWorkInstructions);
    if (
        userFilteredEquipmentOnMapPerQuayCrane.findIndex(
            ({ quayCraneId }) => quayCraneId === cheId,
        ) > -1
    ) {
        return;
    }

    const yardWorkForThisRtg = Maybe.fromNullable(yardWork[cheId]);
    const trucksWorkingForThisRtg = yardWorkForThisRtg
        .map(({ jobs }) => {
            return jobs
                .map(j => {
                    switch (j.type) {
                        case 'RTGHouseKeepingJob':
                            return undefined;
                        case 'ExternalJob':
                            return undefined;
                        case 'InternalJob':
                            return {
                                truckName: j.truckName,
                            };
                        case 'TruckHouseKeepingJob':
                            return { truckName: j.truckName };
                    }
                })
                .filter((t): t is Truck => Boolean(t));
        })
        .orDefault([]);

    const trucksFilteredWithColor = trucksWorkingForThisRtg
        .map(truck => ({
            ...truck,
            mapData: truckPositions[truck.truckName],
            color: TruckMapColors.GRAY,
        }))
        .filter((t): t is Truck & { color: TruckMapColors; mapData: TruckMapData } =>
            Boolean(t.mapData),
        );

    return (
        <>
            {trucksFilteredWithColor.map((truck, index) => {
                return (
                    <Marker
                        key={index}
                        longitude={truck.mapData.longitude}
                        latitude={truck.mapData.latitude}
                        onClick={() => onTruckClicked(truck.truckName)}
                        color="transparent"
                        anchor="center"
                        style={{
                            width: 28,
                            height: 28,
                        }}
                    >
                        <div key={truck.truckName} className="relative">
                            <TruckMarker
                                shape={shape}
                                truckId={truck.truckName}
                                color={truck.color}
                            />
                            {truck.mapData.hasInaccurateSignal && (
                                <InaccurateSignalTruckMarker shape={shape} />
                            )}
                            {truck.mapData.hasStaleSignal && (
                                <StaleSignalTruckMarker shape={shape} />
                            )}
                        </div>
                    </Marker>
                );
            })}
        </>
    );
};
