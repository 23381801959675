import { SharedSlugs } from '@maersk-global/apmt-dpos-common';
import { FlowZonesService } from '@maersk-global/digital-pull-operating-system-spec';
import { NavigateFunction } from 'react-router-dom';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getParameterizedRoute } from '@/routes/routes';
export type EditModeStartPlaces = 'FlowDetail' | 'ControlPage';
export type EditMode = {
    quayCraneNames: string[];
    startedFrom: { startPlace: EditModeStartPlaces; quayCraneId: string };
};
type MapStore = {
    editModeEnabled: EditMode | false;
    enableEditMode: (editMode: EditMode, navigate: NavigateFunction) => void;
    disableEditMode: () => void;
    saveEditMode: (
        coord: { latitude: number; longitude: number },
        terminalId: string,
    ) => Promise<void>;
};

export const useMapStore = create<MapStore>()(
    devtools(
        (set, get) => {
            return {
                editModeEnabled: false,
                enableEditMode: (editMode, nav) => {
                    set({ editModeEnabled: editMode });
                    nav(getParameterizedRoute(SharedSlugs.mapEdit));
                },
                disableEditMode: () => set({ editModeEnabled: false }),
                saveEditMode: async (
                    coord: { latitude: number; longitude: number },
                    terminalId: string,
                ) => {
                    const cranes = get().editModeEnabled;
                    if (!cranes) {
                        throw new Error('No cranes selected to save');
                    }
                    await FlowZonesService.setStandbyPosition({
                        terminalId,
                        requestBody: { quayCraneIds: cranes.quayCraneNames, customCenter: coord },
                    });
                },
            };
        },
        { name: 'mapStore' },
    ),
);
