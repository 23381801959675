import { SharedSlugs, sharedRoutes } from '@maersk-global/apmt-dpos-common';

import config from '@/config';
import { FlowDetailMapOptions } from '@/constants/enums';
import { defaultLocale } from '@/constants/locales';

const isDev = process.env.NODE_ENV === 'development';
const isAndon = false;

export const routes = {
    api: {
        terminalSseEndpoint: () => `${config.backendApiUrl()}/events`,
    },
    gate: {
        scenarioSettings: sharedRoutes(isAndon, isDev).gate.scenarioSettings,
        workstationSettings: sharedRoutes(isAndon, isDev).gate.workstationSettings,
    },
    andon: (locale = defaultLocale) => sharedRoutes(isAndon, isDev).andon(locale),
    controlOverView: (terminalId: string, locale = defaultLocale) =>
        sharedRoutes(isAndon, isDev).controlOverview(terminalId, locale),
    terminalOverView: (terminalId: string, locale = defaultLocale) =>
        sharedRoutes(isAndon, isDev).flowOverview(terminalId, locale),
    terminalMap: (terminalId: string, locale = defaultLocale) =>
        sharedRoutes(isAndon, isDev).terminalMap(terminalId, locale),
    flowOverview: (terminalId: string, locale = defaultLocale) =>
        sharedRoutes(isAndon, isDev).flowOverview(terminalId, locale),
    yardOverview: (terminalId: string, locale = defaultLocale) =>
        sharedRoutes(isAndon, isDev).yardOverview(terminalId, locale),
    yardDetail: (terminalId: string, equipmentName: string, locale = defaultLocale) =>
        `${sharedRoutes(isAndon, isDev).yardOverview(terminalId, locale)}/${equipmentName}`,
    eventsPage: (terminalId: string, quayCraneShiftId: string, locale = defaultLocale) =>
        `/${locale}/terminal/${terminalId}/events/${quayCraneShiftId}`,
    flowDetail: (
        terminalId: string,
        quayCraneName: string,
        view = FlowDetailMapOptions.MAP,
        locale = defaultLocale,
    ) => `/${locale}/terminal/${terminalId}/flow/${quayCraneName}/${view.toLowerCase()}`,
    settings: {
        index: (terminalId: string, locale = defaultLocale) =>
            sharedRoutes(isAndon, isDev).settings(terminalId, locale),
        featureFlags: (terminalId: string, locale = defaultLocale) =>
            `/${locale}/terminal/${terminalId}/settings/feature-flags`,
    },
};

export const getParameterizedRoute = (sharedSlug: SharedSlugs): string => {
    return `/:locale/terminal/:terminalId${sharedSlug}`;
};
