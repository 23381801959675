import { Layer, Source } from 'react-map-gl/maplibre';

import { useTruckPositionsStore } from '@/store';
import { TruckMapData } from '@/store/truckPositions';
import { Coordinate } from '@/types/coordinates';

type TruckRouteDebuggerProps = {
    quayCraneName: string;
    visibleTruckRoutes: string[];
};

function routesFeatureCollection(
    routes: Coordinate[][],
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
    const routeData = routes.map(route => {
        return {
            type: 'Feature' as const,
            properties: {},
            geometry: {
                type: 'LineString' as const,
                coordinates: route.map(coords => {
                    return [coords.longitude, coords.latitude];
                }),
            },
        };
    });

    return {
        type: 'FeatureCollection',
        features: routeData,
    };
}

export const TruckRouteDebugger = ({
    quayCraneName,
    visibleTruckRoutes,
}: TruckRouteDebuggerProps) => {
    const truckPositions = useTruckPositionsStore(({ truckPositions }) => truckPositions);
    const truckRoutes = visibleTruckRoutes
        .map(truckName => truckPositions[truckName])
        .filter(
            (pos): pos is TruckMapData => pos !== undefined && pos.quayCraneName === quayCraneName,
        );

    const standByRoutes = truckRoutes
        .filter(({ standByRoute }) => standByRoute)
        .map(({ latitude, longitude, standByRoute }) => {
            return [{ latitude, longitude }, ...standByRoute!];
        });

    const underCraneRoutes = truckRoutes
        .filter(({ underCraneRoute }) => underCraneRoute)
        .map(({ latitude, longitude, underCraneRoute }) => {
            return [{ latitude, longitude }, ...underCraneRoute!];
        });

    return (
        <>
            <Source
                id="debugUnderCraneRouteSource"
                type="geojson"
                data={routesFeatureCollection(underCraneRoutes)}
            >
                <Layer
                    id="debugUnderCraneRouteLayer"
                    type="line"
                    layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                    }}
                    paint={{
                        'line-color': 'rgba(235, 64, 52, 0.8)',
                        'line-width': 3,
                    }}
                />
            </Source>
            <Source
                id="debugStandByRouteSource"
                type="geojson"
                data={routesFeatureCollection(standByRoutes)}
            >
                <Layer
                    id="debugStandByRouteLayer"
                    type="line"
                    layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                    }}
                    paint={{
                        'line-color': 'rgba(52, 64, 235, 0.8)',
                        'line-width': 3,
                    }}
                />
            </Source>
        </>
    );
};
