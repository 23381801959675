import { VisibilityState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { Block } from '@/components/atoms/Block/Block';
import { ErrorComponent } from '@/components/atoms/Error/ErrorComponent';
import { Title } from '@/components/atoms/Title/Title';
import { QuayCraneInstructionsButtons } from '@/components/organisms/QuayCraneInstructions/QuayCraneInstructionsButtons';
import {
    WorkQueuesOverview,
    YardWorkQueues,
} from '@/components/organisms/WorkQueuesOverview/WorkQueuesOverview';
import { useTerminalStore } from '@/store/terminalStore';
import { YardWork } from '@/store/yardStore';
import { WorkQueues } from '@/types';

export const QuayCraneInstructions = ({
    workQueues,
    columnVisibilityOptions,
    showButtonLabels = true,
    quayCraneName,
    yardWork,
    vesselVisitId,
    yardWorkQueues,
    consideredCompletedTruckNames,
}: {
    yardWorkQueues: YardWorkQueues;
    workQueues: WorkQueues[];
    vesselVisitId: string;
    yardWork: YardWork;
    columnVisibilityOptions?: VisibilityState;
    showButtonLabels?: boolean;
    quayCraneName: string;
    consideredCompletedTruckNames: string[];
}) => {
    const { t } = useTranslation();
    const { terminalId } = useTerminalStore(state => ({
        quayCraneName: state.quayCraneName,
        terminalId: state.terminalId,
    }));

    if (workQueues.length === 0) {
        return (
            <ErrorComponent>
                {t('pages.flows.workQueuesError', { name: quayCraneName })}
            </ErrorComponent>
        );
    }

    return (
        <div className="flex flex-col gap-3" data-testid="quay_crane_instructions_panel">
            <Block className="items-center justify-between">
                <Title className="mds-font--display-1 flex items-center gap-1">
                    <span className="mds-font--default--bold">{t('labels.instructions')}</span>
                </Title>
                <QuayCraneInstructionsButtons
                    quayCraneName={quayCraneName}
                    terminalId={terminalId}
                    vesselVisitId={vesselVisitId || ''}
                    showButtonLabels={showButtonLabels}
                />
            </Block>
            <WorkQueuesOverview
                quayCraneName={quayCraneName}
                yardWorkQueues={yardWorkQueues}
                terminalId={terminalId}
                vesselVisitId={vesselVisitId}
                consideredCompletedTruckNames={consideredCompletedTruckNames}
                workQueues={workQueues}
                yardWork={yardWork}
                columnVisibilityOptions={columnVisibilityOptions}
            />
        </div>
    );
};
