import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { FlowDetail } from '@/components/organisms/FlowDetail/FlowDetail';
import { useTerminalStore } from '@/store/terminalStore';

export const FlowDetailPage = () => {
    const { setPageTitle } = useTerminalStore(
        state => ({
            setPageTitle: state.setPageTitle,
        }),
        shallow,
    );
    useEffect(() => {
        setPageTitle('');
    }, []);

    return <FlowDetail />;
};
