import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useDroppable,
    useSensor,
    useSensors,
    DragOverlay,
    pointerWithin,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FlowPositionEnum } from '@maersk-global/digital-pull-operating-system-spec';
import { Just, Maybe, Nothing } from 'purify-ts';
import { useState } from 'react';

import { ControlCard, RtgJobData } from '@/components/organisms/Cards/ControlCard/ControlCard';
import { getFromTitle, getToTitle } from '@/components/organisms/JobListTable';
import { YardWorkQueues } from '@/components/organisms/WorkQueuesOverview/WorkQueuesOverview';
import { DEFAULT_TRUCK_SHAPE } from '@/constants/settings';
import { ColumnKeys, SortState, useControlPageStore } from '@/store';
import { filterYardWorkForQuayCrane } from '@/store/yardStore';
import {
    AssignedPosition,
    MoveInstructionsPerQuayCrane,
    QuayCraneWithShape,
    TruckShape,
    WorkType,
    YardWorkInstructions,
} from '@/types';
import { cn } from '@/utils/cn';

type DraggableContainerProps = {
    sortableItems: SortState;
    yardWorkPerQuayCrane: Record<string, YardWorkInstructions>;
    yardWorkQueues: YardWorkQueues;
    toggleRtgCard: (cheId: string, checked: boolean) => void;
    toggleQuayCraneCard: (cheId: string, checked: boolean) => void;
    workQueuesPerQuayCrane: MoveInstructionsPerQuayCrane;
    updateColumns: (data: SortState, dragEnd: boolean) => void;
};
export const DraggableContainer = ({
    sortableItems,
    workQueuesPerQuayCrane,
    yardWorkPerQuayCrane,
    toggleRtgCard,
    toggleQuayCraneCard,
    yardWorkQueues,
    updateColumns,
}: DraggableContainerProps) => {
    const [activeId, setActiveId] = useState<UniqueIdentifier | undefined>();
    const [opened, setOpened] = useState<UniqueIdentifier[]>([]);
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const userSelectedQuayCranes = useControlPageStore(state => state.userSelectedQuayCranes);

    const columnsWithQuayCraneIds: [ColumnKeys, { kind: 'qc' | 'rtg'; id: UniqueIdentifier }[]][] =
        Object.entries(sortableItems).map(([columnString, ids]) => [
            columnString as ColumnKeys,
            ids,
        ]);

    // Some complicated logic to make sure:
    // show no empty columns when not dragging
    // only show one extra column when dragging
    // don't show an extra column when we move the last card from a column (making it empty)
    const containers: {
        columnsWithQuayCraneIds: [ColumnKeys, { kind: 'qc' | 'rtg'; id: UniqueIdentifier }[]][];
        done: boolean;
    } = columnsWithQuayCraneIds.reduce(
        (acc, [columnKey, quayCraneIdsForColumn]) => {
            const allCardsExceptTheOneCurrentlyDragging = quayCraneIdsForColumn.filter(a => {
                return a.id !== activeId;
            });

            if (allCardsExceptTheOneCurrentlyDragging.length > 0) {
                // at least card, so we need to add more columns
                return {
                    columnsWithQuayCraneIds: [
                        ...acc.columnsWithQuayCraneIds,
                        [columnKey, quayCraneIdsForColumn],
                    ],
                    done: false,
                };
            } else if (activeId === undefined) {
                // Not dragging, we are done
                return { ...acc, done: true };
            } else {
                if (acc.done) {
                    // If we are dragging and done, no more columns need to be added
                    return acc;
                }

                // We are dragging, and we are not yet done, so we add one more empty column
                return {
                    columnsWithQuayCraneIds: [
                        ...acc.columnsWithQuayCraneIds,
                        [columnKey, quayCraneIdsForColumn],
                    ],
                    done: true,
                };
            }
        },
        {
            columnsWithQuayCraneIds: [] as [
                ColumnKeys,
                { kind: 'qc' | 'rtg'; id: UniqueIdentifier }[],
            ][],
            done: false as boolean,
        },
    );
    const isDragging = activeId !== undefined;
    const draggingType = columnsWithQuayCraneIds
        .map(([, ids]) => ids)
        .flat(1)
        .find(({ id }) => id === activeId);

    if (!sortableItems) return null;

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={pointerWithin}
            modifiers={[restrictToWindowEdges]}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
        >
            {isDragging && <DarkOverlay />}
            {containers.columnsWithQuayCraneIds.map(([id, items], index) => {
                return (
                    <Container
                        activeId={activeId}
                        key={id}
                        id={id}
                        items={items}
                        index={index}
                        userSelectedQuayCranes={userSelectedQuayCranes}
                        workQueuesPerQuayCrane={workQueuesPerQuayCrane}
                        toggleRtgCard={toggleRtgCard}
                        toggleQuayCraneCard={toggleQuayCraneCard}
                        yardWorkQueues={yardWorkQueues}
                        yardWorkPerQuayCrane={yardWorkPerQuayCrane}
                        opened={opened}
                        onOpened={(id: UniqueIdentifier) =>
                            setOpened(opened => {
                                if (opened.includes(id)) {
                                    return opened.filter(a => id !== a);
                                } else {
                                    return [...opened, id];
                                }
                            })
                        }
                    />
                );
            })}
            <DragOverlay>
                {activeId && draggingType ? (
                    <CardWrapper
                        toggleQuayCraneCard={() => {}}
                        toggleRtgCard={() => {}}
                        dragging={true}
                        id={{ id: activeId, kind: draggingType.kind }}
                        truckShape={userSelectedQuayCranes.find(
                            thing => thing.kind === 'qc' && thing.quayCraneId === activeId,
                        )}
                        yardWorkPerQuayCrane={yardWorkPerQuayCrane}
                        workQueuesPerQuayCrane={workQueuesPerQuayCrane}
                        opened={opened}
                        onOpened={() => {}}
                        yardWorkQueues={yardWorkQueues}
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    );

    function findContainer(id: UniqueIdentifier | undefined) {
        if (id === undefined) {
            return undefined;
        }
        if (id in sortableItems) {
            return id;
        }
        const keys = Object.keys(sortableItems) as ColumnKeys[];
        return keys.find(key => sortableItems[key]?.findIndex(thing => thing.id === id) > -1);
    }

    function handleDragStart(event: DragStartEvent) {
        const { active } = event;
        const { id } = active;
        setActiveId(id);
    }

    function handleDragOver(event: DragOverEvent) {
        const { active, over } = event;

        const { id } = active;
        const overId = over?.id;

        // Find the containers
        const activeContainer = findContainer(id) as ColumnKeys;
        const overContainer = findContainer(overId) as ColumnKeys;

        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return;
        }
        const prev = sortableItems;
        const activeItems = prev[activeContainer];
        const overItems = prev[overContainer];

        // Find the indexes for the items
        const activeIndex = activeItems.findIndex(thing => thing.id === id);
        const overIndex =
            overId !== undefined ? overItems.findIndex(thing => thing.id === overId) : undefined;

        let newIndex;
        if (overId && overId in prev) {
            // We're at the root droppable of a container
            newIndex = overItems.length + 1;
        } else {
            const insertAfterLastItem: boolean | null =
                over &&
                overIndex === overItems.length - 1 &&
                active.rect.current.translated &&
                active.rect.current.translated.left > over.rect.left + over.rect.width;

            const modifier: number = insertAfterLastItem ? 1 : 0;

            newIndex = overIndex ?? -1 >= 0 ? overIndex ?? -1 + modifier : overItems.length + 1;
        }

        const newColumns = {
            ...prev,
            [activeContainer]: [...prev[activeContainer].filter(item => item.id !== active.id)],
            [overContainer]: [
                ...prev[overContainer].slice(0, newIndex),
                sortableItems[activeContainer][activeIndex],
                ...prev[overContainer].slice(newIndex, prev[overContainer].length),
            ],
        };

        updateColumns(newColumns, false);
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;
        const { id } = active;
        const overId = over?.id;

        const activeContainer = findContainer(id) as ColumnKeys;
        const overContainer = findContainer(overId);

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return;
        }

        const activeIndex = sortableItems[activeContainer].findIndex(
            thing => thing.id === active.id,
        );
        const overIndex = sortableItems[overContainer].findIndex(thing => thing.id === overId!);

        const newColumns = {
            ...sortableItems,
            [overContainer]: arrayMove(sortableItems[overContainer], activeIndex, overIndex),
        };
        updateColumns(newColumns, true);

        setActiveId(undefined);
    }
};

export function CardWrapper(props: {
    id: { kind: 'qc' | 'rtg'; id: UniqueIdentifier };
    dragging: boolean;
    workQueuesPerQuayCrane: MoveInstructionsPerQuayCrane;
    toggleRtgCard: (cheId: string, checked: boolean) => void;
    toggleQuayCraneCard: (cheId: string, checked: boolean) => void;
    yardWorkQueues: YardWorkQueues;
    yardWorkPerQuayCrane: Record<string, YardWorkInstructions>;
    truckShape?: TruckShape;
    opened: UniqueIdentifier[];
    onOpened: (id: UniqueIdentifier) => void;
}) {
    const { id, dragging, truckShape } = props;
    //todo: yard enabled
    const yardWorkPerQuayCrane = filterYardWorkForQuayCrane(
        props.yardWorkPerQuayCrane,
        String(id.id),
    );
    if (id.kind === 'qc') {
        return (
            <ControlCard
                kind="qc"
                open={props.opened.includes(id.id)}
                toggleRtgCard={props.toggleRtgCard}
                toggleQuayCraneCard={props.toggleQuayCraneCard}
                consideredCompletedTruckNames={
                    props.workQueuesPerQuayCrane[id.id]?.consideredCompletedTruckNames ?? []
                }
                onOpened={props.onOpened}
                key={id.id}
                id={id.id}
                isDragging={dragging}
                quayCraneName={String(id.id)}
                yardWorkQueues={props.yardWorkQueues}
                workQueues={props.workQueuesPerQuayCrane[id.id]?.workQueues ?? []}
                yardWork={yardWorkPerQuayCrane}
                truckShape={truckShape?.shape ?? DEFAULT_TRUCK_SHAPE}
            />
        );
    } else {
        const jobs: RtgJobData[] =
            props.yardWorkPerQuayCrane[id.id]?.jobs?.map(j => {
                return {
                    instruction:
                        j.type === 'ExternalJob'
                            ? j.assignedPosition === AssignedPosition.STAGING
                                ? Just(FlowPositionEnum.PULL)
                                : Just(FlowPositionEnum.UNDER_CRANE)
                            : Just(j.flowPosition),
                    from: getFromTitle(j),
                    to: getToTitle(j),
                    container: j.container,
                    direction: getDirectionForJob(j),
                    jobNumber: Maybe.fromNullable(j.index),
                };
            }) ?? [];
        return (
            <ControlCard
                che={id.id as string}
                isDragging={dragging}
                toggleRtgCard={props.toggleRtgCard}
                toggleQuayCraneCard={props.toggleQuayCraneCard}
                kind="rtg"
                jobs={jobs}
                open={props.opened.includes(id.id)}
                onOpened={props.onOpened}
                key={id.id}
            />
        );
    }
}

const getDirectionForJob = (
    job: YardWorkInstructions['jobs'][number],
): Maybe<'receiving' | 'delivering'> => {
    switch (job.type) {
        case 'RTGHouseKeepingJob':
            return Nothing;
        case 'ExternalJob':
            switch (job.work) {
                case WorkType.FETCH:
                    return Just('receiving');
                case WorkType.PUT:
                    return Just('delivering');
            }
        // eslint-disable-next-line no-fallthrough
        case 'InternalJob':
        case 'TruckHouseKeepingJob':
            switch (job.work) {
                case WorkType.FETCH:
                    return Just('receiving');
                case WorkType.PUT:
                    return Just('delivering');
            }
        // eslint-disable-next-line no-fallthrough
    }
};
export function SortableItem(props: {
    id: { kind: 'qc' | 'rtg'; id: UniqueIdentifier };
    workQueuesPerQuayCrane: MoveInstructionsPerQuayCrane;
    toggleRtgCard: (cheId: string, checked: boolean) => void;
    toggleQuayCraneCard: (cheId: string, checked: boolean) => void;
    yardWorkPerQuayCrane: Record<string, YardWorkInstructions>;
    truckShape?: TruckShape;
    yardWorkQueues: YardWorkQueues;
    opened: UniqueIdentifier[];
    onOpened: (id: UniqueIdentifier) => void;
}) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.id.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <CardWrapper
                yardWorkQueues={props.yardWorkQueues}
                id={props.id}
                dragging={false}
                toggleRtgCard={props.toggleRtgCard}
                toggleQuayCraneCard={props.toggleQuayCraneCard}
                truckShape={props.truckShape}
                workQueuesPerQuayCrane={props.workQueuesPerQuayCrane}
                yardWorkPerQuayCrane={props.yardWorkPerQuayCrane}
                opened={props.opened}
                onOpened={props.onOpened}
            />
        </div>
    );
}

export function Container(props: {
    id: UniqueIdentifier;
    activeId?: UniqueIdentifier;
    yardWorkPerQuayCrane: Record<string, YardWorkInstructions>;
    items: { kind: 'qc' | 'rtg'; id: UniqueIdentifier }[];
    userSelectedQuayCranes: QuayCraneWithShape[];
    yardWorkQueues: YardWorkQueues;
    workQueuesPerQuayCrane: MoveInstructionsPerQuayCrane;
    toggleRtgCard: (cheId: string, checked: boolean) => void;
    toggleQuayCraneCard: (cheId: string, checked: boolean) => void;
    index: number;
    opened: UniqueIdentifier[];
    onOpened: (id: UniqueIdentifier) => void;
}) {
    const { id, items, activeId, userSelectedQuayCranes } = props;
    const { setNodeRef } = useDroppable({
        id,
    });
    const cardWidth = 400;
    const cardMargin = 16;
    const cardWidthClass = `w-[${cardWidth}px]`;

    return (
        <SortableContext id={String(id)} items={items} strategy={verticalListSortingStrategy}>
            <div
                ref={setNodeRef}
                className={cn(
                    'absolute top-0 z-30 m-2 max-h-full flex-1 space-y-4 overflow-y-hidden rounded-[4px] hover:z-50',
                    cardWidthClass,
                    {
                        'h-full bg-[rgba(255,255,255,0.6)]': activeId !== undefined,
                    },
                )}
                style={{ right: (cardWidth + cardMargin) * props.index }}
            >
                {items.map(id => (
                    <SortableItem
                        truckShape={userSelectedQuayCranes.find(
                            thing => thing.kind === 'qc' && thing.quayCraneId === id.id,
                        )}
                        key={id.id}
                        id={id}
                        toggleRtgCard={props.toggleRtgCard}
                        toggleQuayCraneCard={props.toggleQuayCraneCard}
                        yardWorkQueues={props.yardWorkQueues}
                        workQueuesPerQuayCrane={props.workQueuesPerQuayCrane}
                        yardWorkPerQuayCrane={props.yardWorkPerQuayCrane}
                        opened={props.opened}
                        onOpened={props.onOpened}
                    />
                ))}
            </div>
        </SortableContext>
    );
}

export const DarkOverlay = () => {
    return <div className="absolute left-0 top-0 z-0 size-full bg-slate-900 opacity-40"></div>;
};
