import { SharedCookieNames } from '@maersk-global/apmt-dpos-common';
import { Divider } from '@maersk-global/apmt-react-components';
import { Drawer } from '@maersk-global/apmt-react-drawer';
import { useState } from 'react';
import { useCookie } from 'react-use';
import { shallow } from 'zustand/shallow';

import { Block } from '@/components/atoms/Block/Block';
import { DrawerInner } from '@/components/atoms/DrawerInner/DrawerInner';
import { FlowStatusLabel } from '@/components/atoms/FlowStatusLabel/FlowStatusLabel';
import { QuayCraneTitle } from '@/components/molecules/QuayCraneTitle/QuayCraneTitle';
import { FlowDetailDrawerAndons } from '@/components/organisms/FlowDetailDrawer/FlowDetailDrawerAndons';
import { FlowDetailDrawerDelayCodes } from '@/components/organisms/FlowDetailDrawer/FlowDetailDrawerDelayCodes';
import { FlowDetailDrawerQuayCraneStatusInfo } from '@/components/organisms/FlowDetailDrawer/FlowDetailDrawerQuayCraneStatusInfo';
import { QuayCraneInstructions } from '@/components/organisms/QuayCraneInstructions/QuayCraneInstructions';
import { useTerminalStore, useTruckPositionsStore } from '@/store';
import { getYardWorkQueues, splitYardWorkPerQuayCrane, useYardStore } from '@/store/yardStore';
import { WorkQueues } from '@/types';
import { Andons } from '@/types/andons';
import { DelayCodes } from '@/types/delayCodes';
import { filterCompletedTrucks } from '@/utils/filterCompletedTrucks';
import { getFlowStatus } from '@/utils/getFlowStatus';

import { EditStandByPositionDialog } from '../EditStandbyPositionDialog/EditStandByPositionDialog';

export type FlowDetailDrawerProps = {
    delayCodes: DelayCodes;
    andons: Andons;
    quayCraneName: string;
    workQueues: WorkQueues[];
    consideredCompletedTruckNames: string[];
    tosOnlyCraneTriggersEnabled: boolean;
    toggleTosOnlyTriggers: (checked: boolean) => void;
};

export const FlowDetailDrawer = ({
    quayCraneName,
    delayCodes,
    consideredCompletedTruckNames,
    andons,
    workQueues,
    toggleTosOnlyTriggers,
    tosOnlyCraneTriggersEnabled,
}: FlowDetailDrawerProps) => {
    const { quayCraneStatusInfoPerQuayCrane } = useTruckPositionsStore(
        state => ({
            quayCraneStatusInfoPerQuayCrane: state.quayCraneStatusInfoPerQuayCrane,
        }),
        shallow,
    );
    const workQueuesWithoutCompletedTrucks = filterCompletedTrucks(
        workQueues,
        consideredCompletedTruckNames,
    );
    const [selectedQuayCraneIds, setSelectedQuayCraneIds] = useState<string[]>([quayCraneName]);
    const allQuayCranes = useTerminalStore(store => store.allQuayCranes);

    const quayCraneStatusInfo = quayCraneStatusInfoPerQuayCrane[quayCraneName];
    const { vesselVisitId } = workQueues.find(workQueue => workQueue.vesselVisitId !== null) || {
        vesselVisitId: 'Unknown',
    };
    const { flowStatus } = getFlowStatus(workQueuesWithoutCompletedTrucks);

    const yardInstructions = useYardStore(store => store.yardWorkInstructions);
    const [yardEnabled] = useCookie(SharedCookieNames.YardPage);
    const yardWorkQueues = getYardWorkQueues(yardInstructions);
    const yardWorkPerQuayCrane = yardEnabled ? splitYardWorkPerQuayCrane(yardInstructions) : {};
    return (
        <Drawer canBeClosed={false} open={true} className="h-full">
            <DrawerInner>
                <div className="mb-6">
                    <Block className="items-center gap-3">
                        <QuayCraneTitle
                            quayCraneName={quayCraneName}
                            vesselVisitId={vesselVisitId}
                        />
                        <FlowStatusLabel
                            flowStatus={flowStatus}
                            weight="light"
                            fit="medium"
                            shape="pill"
                        />
                        {allQuayCranes && (
                            <div className="ml-auto flex flex-row gap-2">
                                <EditStandByPositionDialog
                                    onOpenChange={open => {
                                        if (open) {
                                            setSelectedQuayCraneIds([quayCraneName]);
                                        }
                                    }}
                                    selectedQuayCraneIds={selectedQuayCraneIds}
                                    allQuayCranes={allQuayCranes}
                                    setSelectedQuayCraneIds={setSelectedQuayCraneIds}
                                    mapEditStartPlace={{
                                        startPlace: 'FlowDetail',
                                        quayCraneId: quayCraneName,
                                    }}
                                />
                            </div>
                        )}
                    </Block>
                </div>
                <div className="mb-5">
                    <Divider className="-mx-4" />

                    <FlowDetailDrawerQuayCraneStatusInfo
                        quayCraneName={quayCraneName}
                        quayCraneStatusInfo={quayCraneStatusInfo}
                        toggleTosOnlyTriggers={toggleTosOnlyTriggers}
                        tosOnlyCraneTriggersEnabled={tosOnlyCraneTriggersEnabled}
                    />
                </div>
                {delayCodes.length > 0 && (
                    <div className="mb-5">
                        <Divider className="-mx-4" />
                        <FlowDetailDrawerDelayCodes delayCodes={delayCodes} />
                    </div>
                )}
                <div className="mb-5">
                    <Divider className="-mx-4" />
                    <FlowDetailDrawerAndons andons={andons} />
                </div>
                <div className="mb-5">
                    <Divider className="-mx-4" />
                    <QuayCraneInstructions
                        vesselVisitId={vesselVisitId}
                        workQueues={workQueues}
                        quayCraneName={quayCraneName}
                        consideredCompletedTruckNames={consideredCompletedTruckNames}
                        yardWork={yardWorkPerQuayCrane[quayCraneName] ?? {}}
                        yardWorkQueues={yardWorkQueues}
                    />
                </div>
            </DrawerInner>
        </Drawer>
    );
};
