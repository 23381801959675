import { CheType } from '@/constants/enums';
import { useChePositionsStore } from '@/store/chePositions';

import { YardStackingEquipmentMapMarker } from './YardStackingEquipmentMapMarker';

export const AllYardStackingEquipmentMarkers = ({
    onYardStackingEquipmentClicked,
}: {
    onYardStackingEquipmentClicked?: (type: CheType, id: string) => void;
}) => {
    const ysePositions = useChePositionsStore(state => state.positions);

    return (
        <>
            {Object.values(ysePositions[CheType.RUBBER_TYRED_GANTRY] ?? {}).map(yse => {
                return (
                    <YardStackingEquipmentMapMarker
                        key={`${yse.containerHandlingEquipmentType}-${yse.containerHandlingEquipmentId}`}
                        yardStackingEquipment={yse}
                        onYardStackingEquipmentClicked={
                            onYardStackingEquipmentClicked &&
                            (() =>
                                onYardStackingEquipmentClicked(
                                    yse.containerHandlingEquipmentType,
                                    yse.containerHandlingEquipmentId,
                                ))
                        }
                    />
                );
            })}
        </>
    );
};
