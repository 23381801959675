import { isActiveRoute, SharedSlugs } from '@maersk-global/apmt-dpos-common';
import { Button } from '@maersk-global/apmt-react-components';
import { SvgArrowAllDirections } from '@maersk-global/apmt-react-icons';
import { useQuery } from '@tanstack/react-query';
import * as turf from '@turf/turf';
import { LngLat, LngLatBounds } from 'maplibre-gl';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, {
    AttributionControl,
    ErrorEvent,
    FullscreenControl,
    Layer,
    MapLayerMouseEvent,
    MapRef,
    NavigationControl,
    Source,
    ViewStateChangeEvent,
} from 'react-map-gl/maplibre';
import { useLocation } from 'react-router-dom';
import { useCookie } from 'react-use';

import { getFlowPositionsByQuayCrane } from '@/api/getFlowPositionsByQuayCrane';
import { TruckRouteDebugger } from '@/components/organisms/Map/TruckRouteDebugger';
import { CookieNames } from '@/constants/cookies';
import { TruckMapShapes } from '@/constants/enums';
import { useTerminalSettings } from '@/hooks/terminalInfo/TerminalInfoProvider';
import { useControlPageStore, useTerminalStore } from '@/store';
import { useYardStore } from '@/store/yardStore';
import { isTest } from '@/test/helpers/isTest';
import { QuayCraneWithShape } from '@/types';
import { FeatureLayerData, MapComponentMapProps, MapViewState } from '@/types/map';
import { cn } from '@/utils/cn';

import { AllTruckMarkers } from './AllTruckMarkers';
import { AllYardStackingEquipmentMarkers } from './AllYardStackingEquipmentMarkers';
import { FlowPositionsLayer } from './FlowPositions';
import { FlowPositionsTooltip } from './FlowPositionsTooltip';
import { createLngLatBoundsFrom, rotatePolygon } from './geo';
import { QuayCraneYardStackingEquipmentMarkers } from './QuayCraneYardStackingEquipmentMarkers';
import { TerminalMapDebug } from './TerminalMapDebug';
import { TerminalMapLegend } from './TerminalMapLegend';
import { TerminalMapLayer } from './TerminalMapOverlay';
import { TruckMarkers } from './TruckMarkers';
import { TruckMarkersForRtg } from './TruckMarkersForRtg';
// Maplibre styles
import 'maplibre-gl/dist/maplibre-gl.css';

const DRAGGING_CIRCLE_LAYER = 'circle-layer';

export const MapComponent = ({
    className,
    rotate = true,
    quayCranesToShow,
    cancelEdit,
    confirmEdit,
    editMode,
}: MapComponentMapProps) => {
    const [dragging, setDragging] = useState(false);
    const [draggingCoord, setDraggingCoord] = useState<Coordinate | undefined>(undefined);
    const { t } = useTranslation();
    const yardWorkInstructions = useYardStore(store => store.yardWorkInstructions);
    const [terminalId, singleQuayCrane] = useTerminalStore(state => [
        state.terminalId,
        state.quayCraneName,
    ]);
    const { userSelectedQuayCranes, setMapViewState } = useControlPageStore(state => ({
        userSelectedQuayCranes: state.userSelectedQuayCranes,
        setMapViewState: state.setMapViewState,
    }));
    const { pathname } = useLocation();
    const [flowPositionData, setFlowPositionData] = useState<FeatureLayerData | undefined>();
    const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([]);
    const [debugMode] = useCookie(CookieNames.DebugMode);
    const [visibleTruckRoutes, setVisibleTruckRoutes] = useState<string[]>([]);
    const mapRef = useRef<MapRef | null>(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const settings = useTerminalSettings();

    const initialViewState: MapViewState = {
        latitude: settings.mapCenter.latitude,
        longitude: settings.mapCenter.longitude,
        zoom: settings.mapMinZoom + (settings.mapMaxZoom - settings.mapMinZoom) / 2,
        bearing: rotate ? settings.mapBearing : 0,
    };
    const quayCranesToRender: QuayCraneWithShape[] = singleQuayCrane
        ? [
              {
                  columnKey: 'root',
                  kind: 'qc',
                  quayCraneId: singleQuayCrane,
                  shape: TruckMapShapes.CIRCLE,
              },
          ]
        : editMode && quayCranesToShow
        ? quayCranesToShow.map(qc => {
              return {
                  columnKey: 'root',
                  kind: 'qc',
                  quayCraneId: qc,
                  shape: TruckMapShapes.CIRCLE,
              };
          })
        : userSelectedQuayCranes;
    const isControlPage = isActiveRoute(pathname, SharedSlugs.controlOverview);

    const mapBoundaries = LngLatBounds.fromLngLat(
        new LngLat(settings.mapCenter.longitude, settings.mapCenter.latitude),
        settings.mapBoundsRadiusInMeters,
    );

    // Rotated focus area to non rotated map because maplibre LngLatBounds always makes rectangle based on non rotated map
    const rotatedFocusArea = useMemo(
        () => rotatePolygon(settings.mapFocusArea, settings.mapBearing * -1), // maplibre and turf rotate the other way around, hence the times -1
        [settings],
    );
    // calculate fit bounds based on focus area
    const fitBoundariesLngLat = useMemo(
        () => createLngLatBoundsFrom(rotatedFocusArea),
        [rotatedFocusArea],
    );

    const handleLoad = useCallback(() => {
        setMapLoaded(true);
    }, []);

    const handleMoveOrZoomEnd = useCallback((e: ViewStateChangeEvent) => {
        setMapViewState({
            latitude: e.viewState.latitude,
            longitude: e.viewState.longitude,
            zoom: e.viewState.zoom,
            bearing: e.viewState.bearing,
        });
    }, []);

    const handleMouseMove = (event: MapLayerMouseEvent) => {
        if (dragging) {
            const unclamped = { latitude: event.lngLat.lat, longitude: event.lngLat.lng };
            const newCoord = clampCoordinateWithinPolygon(unclamped, clampArea!);
            setDraggingCoord(newCoord);
        }

        const {
            features,
            point: { x, y },
        } = event;

        const hoveredFeature = features && features[0];

        setFlowPositionData({
            quayCraneName: hoveredFeature?.properties?.quayCraneName,
            flowPositionName: hoveredFeature?.properties?.flowPositionName,
            x,
            y,
        });

        const isHoveringStandBy =
            features?.find(f => f.properties?.draggingFlowPosition) !== undefined;

        if (isHoveringStandBy || dragging) {
            setCursor('move');
        } else {
            setCursor('auto');
        }
    };

    const getInteractiveLayerId = () => {
        // Map rendered & Layers rendered?
        if (!mapRef.current) {
            return;
        }

        const renderedLayers = mapRef.current
            .getMap()
            .getStyle()
            .layers.map(layer => layer.id);

        setInteractiveLayerIds(renderedLayers);
    };

    const handleMapResize = () => {
        handleFitBounds();
    };

    const handleError = useCallback((error: ErrorEvent) => {
        if (!isTest) {
            console.error(error);
        }
    }, []);

    const handleFitBounds = () => {
        if (!mapLoaded || !mapRef.current) {
            if (debugMode) {
                console.warn(
                    'handleFitBounds called while map is not loaded or mapRef is not set yet',
                    mapLoaded,
                    mapRef.current,
                );
            }
            return;
        }

        mapRef.current
            .fitBounds(fitBoundariesLngLat, {
                duration: 0,
                padding: 0,
            })
            .setBearing(rotate ? settings.mapBearing : 0);
    };

    const handleTruckClicked = (truckId: string) => {
        if (truckId === '') {
            return;
        }

        const truckIsVisible = visibleTruckRoutes.includes(truckId);

        setVisibleTruckRoutes(
            truckIsVisible
                ? visibleTruckRoutes.filter(id => id !== truckId)
                : [...visibleTruckRoutes, truckId],
        );
    };

    useEffect(() => {
        if (!mapRef.current?.isStyleLoaded()) {
            return;
        }

        getInteractiveLayerId();
    }, [mapRef.current?.isStyleLoaded()]);

    useEffect(() => {
        if (mapLoaded) {
            handleFitBounds();
        }
    }, [mapLoaded]);

    const { data: flowPositions } = useQuery({
        queryKey: ['getFlowPositionForEditing'],
        queryFn: () => {
            const quayCraneId =
                quayCranesToShow !== undefined && quayCranesToShow[0] !== undefined
                    ? quayCranesToShow[0]
                    : '';
            return getFlowPositionsByQuayCrane(terminalId, quayCraneId);
        },
        refetchInterval: 10000,
        enabled: !!editMode && quayCranesToShow !== undefined && quayCranesToShow.length > 0,
    });
    const centerOfEditingStandByPosition =
        !!editMode && flowPositions && getCenterCoordinates(flowPositions);
    const clampArea = !!editMode && flowPositions ? flowPositions.quayArea : undefined;
    const quayCranes = quayCranesToRender
        .filter((qc): qc is QuayCraneWithShape & { kind: 'qc' } => qc.kind === 'qc')
        .map(qc => qc.quayCraneId);
    const rtgs = quayCranesToRender
        .filter((rtg): rtg is QuayCraneWithShape & { kind: 'rtg' } => rtg.kind === 'rtg')
        .map(qc => qc.cheId);

    useEffect(() => {
        if (flowPositionData === undefined) {
            return;
        }

        getInteractiveLayerId();
    }, [flowPositionData]);

    const [cursor, setCursor] = useState<string>('auto');
    return (
        <div className="flex h-full flex-col">
            {editMode && (
                <div className="flex h-[56px] w-full items-center justify-between bg-feedback-warning-weak px-6 py-3">
                    <div className="flex flex-row items-center gap-2">
                        <SvgArrowAllDirections />
                        Drag the standby-circle to the desired destination
                    </div>
                    <div className="flex items-center gap-3">
                        <Button
                            variant="primaryOutlined"
                            fit="small"
                            onClick={async () => {
                                cancelEdit?.();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            fit="small"
                            disabled={draggingCoord === undefined}
                            onClick={async () => {
                                if (!draggingCoord || !confirmEdit) {
                                    return;
                                }
                                await confirmEdit(draggingCoord);
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            )}
            <div className={cn('relative w-full bg-gray-200 grow', className)}>
                {!mapLoaded && (
                    <div className="absolute inset-0 bg-pink-200 opacity-20">Loading...</div>
                )}

                <Map
                    ref={mapRef}
                    style={{ flexGrow: 1 }}
                    initialViewState={initialViewState}
                    minZoom={!debugMode ? settings.mapMinZoom : undefined}
                    maxZoom={!debugMode ? settings.mapMaxZoom : undefined}
                    maxBounds={!debugMode ? mapBoundaries : undefined}
                    pitchWithRotate={false}
                    dragRotate={!editMode}
                    scrollZoom={!editMode}
                    RTLTextPlugin={''}
                    attributionControl={false}
                    reuseMaps
                    cursor={cursor}
                    interactiveLayerIds={interactiveLayerIds}
                    onLoad={handleLoad}
                    onMouseDown={(event: MapLayerMouseEvent) => {
                        const circleClicked = event.features?.find(f => {
                            return f.layer.id === DRAGGING_CIRCLE_LAYER;
                        });
                        if (circleClicked) {
                            setDragging(true);
                        }
                    }}
                    onMoveEnd={handleMoveOrZoomEnd}
                    dragPan={!editMode}
                    onZoomEnd={handleMoveOrZoomEnd}
                    onMouseMove={handleMouseMove}
                    onResize={handleMapResize}
                    onError={handleError}
                    onMouseUp={(event: MapLayerMouseEvent) => {
                        setDragging(false);
                        if (debugMode) {
                            console.log({
                                latitude: event.lngLat.lat,
                                longitude: event.lngLat.lng,
                            });
                        }
                    }}
                >
                    <AttributionControl customAttribution={t('pages.map.credits') || ''} />
                    <FullscreenControl position="bottom-right" style={{ marginRight: '16px' }} />
                    {editMode && centerOfEditingStandByPosition ? (
                        <DraggableFlowPosition
                            draggingCoord={draggingCoord}
                            start={centerOfEditingStandByPosition}
                        />
                    ) : null}
                    <NavigationControl
                        position="bottom-right"
                        style={{ marginRight: '16px' }}
                        showCompass={false}
                    />
                    <TerminalMapLayer
                        terminalImageCoordinates={settings.mapTerminalImageCoordinates}
                    />
                    <TerminalMapLegend />
                    {debugMode && (
                        <TerminalMapDebug
                            bounds={[mapBoundaries, fitBoundariesLngLat]}
                            polys={[
                                [...settings.mapFocusArea, settings.mapFocusArea[0]!],
                                rotatedFocusArea,
                            ]}
                        />
                    )}
                    {flowPositionData?.flowPositionName && (
                        <FlowPositionsTooltip flowPosition={flowPositionData} />
                    )}
                    {!singleQuayCrane && !isControlPage && !editMode && (
                        <>
                            <AllYardStackingEquipmentMarkers />
                            <AllTruckMarkers onTruckClicked={handleTruckClicked} />
                        </>
                    )}
                    {(singleQuayCrane || isControlPage || editMode) && (
                        <>
                            {quayCranesToRender
                                .filter(
                                    (thing): thing is QuayCraneWithShape & { kind: 'qc' } =>
                                        thing.kind === 'qc',
                                )
                                .map(({ quayCraneId, shape }) => {
                                    return (
                                        <Fragment key={quayCraneId}>
                                            <FlowPositionsLayer
                                                editFlowPosition={Boolean(editMode)}
                                                quayCraneName={quayCraneId}
                                            />

                                            {!editMode && (
                                                <>
                                                    <TruckMarkers
                                                        shape={shape}
                                                        quayCraneName={quayCraneId}
                                                        onTruckClicked={handleTruckClicked}
                                                    />
                                                </>
                                            )}

                                            {debugMode && (
                                                <TruckRouteDebugger
                                                    quayCraneName={quayCraneId}
                                                    visibleTruckRoutes={visibleTruckRoutes}
                                                />
                                            )}
                                        </Fragment>
                                    );
                                })}
                        </>
                    )}
                    <QuayCraneYardStackingEquipmentMarkers
                        terminalId={terminalId}
                        quayCraneNames={quayCranes}
                        yardStackingEquipment={rtgs}
                        yardWorkInstructions={yardWorkInstructions}
                    />

                    {isControlPage &&
                        quayCranesToRender
                            .filter(
                                (thing): thing is QuayCraneWithShape & { kind: 'rtg' } =>
                                    thing.kind === 'rtg',
                            )
                            .map(({ shape, cheId }) => {
                                return (
                                    <TruckMarkersForRtg
                                        key={cheId}
                                        shape={shape}
                                        cheId={cheId}
                                        onTruckClicked={handleTruckClicked}
                                    />
                                );
                            })}
                </Map>
            </div>
        </div>
    );
};
function getCenterCoordinates(
    flowPositions: Awaited<ReturnType<typeof getFlowPositionsByQuayCrane>>,
): Coordinate {
    const coords =
        flowPositions.standby !== undefined && flowPositions.standby.length > 0
            ? flowPositions.standby
            : flowPositions.quayArea!;
    if (coords.length === 0) {
        throw new Error('No coordinates provided');
    }

    let totalLatitude = 0;
    let totalLongitude = 0;

    coords.forEach(coord => {
        totalLatitude += coord.latitude;
        totalLongitude += coord.longitude;
    });

    const latitude = totalLatitude / coords.length;
    const longitude = totalLongitude / coords.length;

    return { latitude, longitude };
}

const DraggableFlowPosition = ({
    start,
    draggingCoord,
}: {
    start: Coordinate;
    draggingCoord: Coordinate | undefined;
}) => {
    const [circleGeoJSON, setCircleGeoJSON] = useState<GeoJSON.Feature | undefined>(undefined);
    const [startCircleGeoJSON, setStartCircleGeoJSON] = useState<GeoJSON.Feature | undefined>(
        undefined,
    );
    useEffect(() => {
        const center =
            draggingCoord === undefined
                ? [start.longitude, start.latitude]
                : [draggingCoord.longitude, draggingCoord.latitude];
        const radius = 25;

        const options = { steps: 64, units: 'meters' as turf.Units };
        const circle = turf.circle(center, radius, options);

        setCircleGeoJSON(circle);

        // When not yet dragging cache start for circle shadow
        if (draggingCoord === undefined) {
            setStartCircleGeoJSON(circle);
        }
    }, [start, draggingCoord]);

    return circleGeoJSON !== undefined ? (
        <>
            <Source
                id="circle"
                type="geojson"
                data={{ ...circleGeoJSON, properties: { draggingFlowPosition: true } }}
            >
                <Layer
                    id={DRAGGING_CIRCLE_LAYER}
                    type="fill"
                    paint={{
                        'fill-color': '#DCF6D9',
                        'fill-opacity': 1,
                    }}
                />
                <Layer
                    id="line-circle-layer"
                    type="line"
                    source="circle"
                    paint={{
                        'line-color': '#40AB35',
                        'line-width': 1.5,
                    }}
                />
            </Source>
            <Source id="circle-shadow" type="geojson" data={startCircleGeoJSON}>
                <Layer
                    id="circle-shadow-layer"
                    type="fill"
                    paint={{
                        'fill-color': '#DCF6D9',
                        'fill-opacity': 0.3,
                    }}
                />
                <Layer
                    id="line-circle-shadow-layer"
                    type="line"
                    source="circle"
                    paint={{
                        'line-color': '#40AB35',
                        'line-width': 1.5,
                        'line-opacity': 0.3,
                    }}
                />
            </Source>
        </>
    ) : null;
};
type Coordinate = { latitude: number; longitude: number };

function clampCoordinateWithinPolygon(coord: Coordinate, polygonCoords: Coordinate[]): Coordinate {
    //TODO: cache areas
    const point = turf.point([coord.longitude, coord.latitude]);
    const polygon = turf.polygon([polygonCoords.map(c => [c.longitude, c.latitude])]);

    const bufferedPolygon = turf.buffer(polygon, -10, { units: 'meters' });

    const polygonLine = turf.multiLineString(bufferedPolygon.geometry.coordinates);
    if (turf.booleanPointInPolygon(point, bufferedPolygon)) {
        return coord;
    } else {
        const nearestPoint = turf.nearestPointOnLine(polygonLine, point);
        return {
            latitude: nearestPoint.geometry.coordinates[1]!,
            longitude: nearestPoint.geometry.coordinates[0]!,
        };
    }
}
