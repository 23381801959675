import { SharedSlugs, isActiveRoute } from '@maersk-global/apmt-dpos-common';
import { Table } from '@maersk-global/apmt-react-components';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl/maplibre';
import { useLocation } from 'react-router-dom';

import { NoTruckInstructions } from '@/components/atoms/NoTruckInstructions/NoTruckInstructions';
import { TableHeader } from '@/components/organisms/TruckInstructionsTable/TableHeader';
import { FlowPositionName } from '@/constants/enums';
import { useControlPageStore, useTruckPositionsStore } from '@/store';
import { YardWork } from '@/store/yardStore';
import { AssignedPosition } from '@/types';
import { humanReadableTime } from '@/utils/dates';

export const filterDuplicateTruckNames = (
    val: { truck: string },
    index: number,
    list: { truck: string }[],
) => {
    const keep =
        index ===
        list.findIndex(item => {
            return item.truck === val.truck;
        });
    return keep;
};
export type RtgInstructionData = {
    truckId: string;
    flowPosition: FlowPositionName | AssignedPosition;
    sentOn: string;
};

export const RtgIntructionsList = ({ yardWork }: { yardWork: YardWork[string] }) => {
    const FLY_TO_ANIMATION_DURATION = 1000;
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const isTerminalMapPage = isActiveRoute(pathname, SharedSlugs.terminalMap);
    const { default: map } = useMap();
    const truckPositions = useTruckPositionsStore(state => state.truckPositions);
    const mapViewState = useControlPageStore(state => state.mapViewState);
    const columnHelper = createColumnHelper<RtgInstructionData>();

    const defaultColumns = useMemo(
        () => [
            columnHelper.accessor('truckId', {
                header: () => (
                    <TableHeader title={t('pages.positions.detailDrawer.truckColumnTitle')} />
                ),
                cell: data => <span className="px-6">{data.getValue()}</span>,
                size: 80,
            }),
            columnHelper.accessor('flowPosition', {
                header: () => (
                    <TableHeader title={t('pages.positions.detailDrawer.instructionColumnTitle')} />
                ),
                cell: data => {
                    const pos = data.getValue();
                    return <span>{t(`pages.positions.yard.${pos}`)}</span>;
                },
                size: 200,
            }),
            columnHelper.accessor('sentOn', {
                header: () => (
                    <TableHeader title={t('pages.positions.detailDrawer.sentColumnTitle')} />
                ),
                cell: data => <span>{data.getValue()}</span>,
            }),
        ],
        [],
    );

    const sortedInstructions: RtgInstructionData[] = yardWork
        .filter(filterDuplicateTruckNames)
        .map(work => {
            return {
                truckId: work.truck,
                flowPosition: work.flowPosition,
                sentOn: work.instructionSentOn && humanReadableTime(work.instructionSentOn),
            };
        });

    const flyToTruckOnMap = (truckId: string) => {
        const truck = truckPositions[truckId];

        if (!truck || !map) {
            return;
        }

        const { latitude, longitude } = truck;

        map.flyTo({
            center: [longitude, latitude],
            zoom: 18,
            duration: 2500,
            essential: true,
        });
    };

    const handleOnRowClicked = (row: Row<RtgInstructionData>) => {
        const { truckId } = row.original;
        flyToTruckOnMap(truckId);
    };
    const handleOnMouseLeaveCell = () => flyBackFromTruckToInitialMapState();

    const flyBackFromTruckToInitialMapState = () => {
        if (!map || !mapViewState) {
            return;
        }

        map.flyTo({
            center: [mapViewState.longitude, mapViewState.latitude],
            zoom: mapViewState.zoom,
            duration: FLY_TO_ANIMATION_DURATION,
            essential: true,
        });
    };

    if (sortedInstructions.length === 0) {
        return <NoTruckInstructions />;
    }

    return (
        <Table<RtgInstructionData>
            tableLayout="fixed"
            data={sortedInstructions}
            columns={defaultColumns}
            {...(isTerminalMapPage && {
                onRowClicked: handleOnRowClicked,
            })}
            {...(isTerminalMapPage && {
                onMouseLeaveCell: handleOnMouseLeaveCell,
            })}
            disableHeader={true}
        />
    );
};
