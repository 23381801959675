import { useQuery } from '@tanstack/react-query';

import { getAllQuayCranesById } from '@/api/getAllQuayCranesById';
import { reactQueryKeys } from '@/constants/queryKeys';
import { useTerminalStore } from '@/store/terminalStore';
const pollInterval = 10 * 1000;
export function useAllQuayCranes() {
    const terminalId = useTerminalStore(state => state.terminalId);

    return useQuery({
        queryKey: [reactQueryKeys.QUAYCRANES, terminalId],
        queryFn: () => getAllQuayCranesById(terminalId),
        enabled: !!terminalId,
        refetchInterval: pollInterval,
    });
}
