import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import { ProfileButton } from '@maersk-global/apmt-react-components';
import { Header as LayoutHeader } from '@maersk-global/apmt-react-layout';
import { Link, useLocation, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import Clock from '@/components/atoms/Clock/Clock';
import { DebugDownloadButton } from '@/components/atoms/DebugDownloadButton/DebugDownloadButton';
import { Title } from '@/components/atoms/Title/Title';
import { BackButton } from '@/components/molecules/BackButton/BackButton';
import { QuayCraneDropdown } from '@/components/organisms/QuayCraneDropdown/QuayCraneDropdown';
import config from '@/config';
import { FlowDetailMapOptions } from '@/constants/enums';
import DposLogoIcon from '@/icons/DposLogo';
import { routes } from '@/routes/routes';
import { useTerminalStore } from '@/store/terminalStore';
export const Header = () => {
    const { quayCraneName, terminalId, pageTitle } = useTerminalStore(
        state => ({
            quayCraneName: state.quayCraneName,
            terminalId: state.terminalId,
            pageTitle: state.pageTitle,
        }),
        shallow,
    );
    const { locale } = useParams();
    const { pathname } = useLocation();
    const { user, logout } = useAuth();

    const showBackButton =
        pathname === routes.flowDetail(terminalId, quayCraneName, FlowDetailMapOptions.MAP) ||
        pathname ===
            routes.flowDetail(terminalId, quayCraneName, FlowDetailMapOptions.VISUALISATION);

    const rightSide = (
        <>
            {user.email && <span className="text-sm">{user.email}</span>}
            <Clock />
            <DebugDownloadButton quayCraneName={quayCraneName} />
            <Link
                to={routes.flowOverview(terminalId, locale)}
                className="text-center text-xs text-gray-400"
                title={`v${config.appVersion}`}
            >
                <div className="flex items-center">
                    <DposLogoIcon data-testid="dpos-logo" className="pr-6" />
                    <ProfileButton
                        className="border-l border-gray-300"
                        userEmail={user.email}
                        onLogout={() => {
                            logout();
                        }}
                        username={user.name}
                    />
                </div>
            </Link>
        </>
    );

    return (
        <LayoutHeader rightSide={rightSide} rightSideClassName="gap-2">
            {pageTitle ? <Title>{pageTitle}</Title> : null}
            {showBackButton ? (
                <div className="min-w-[52px]">
                    <BackButton href={routes.flowOverview(terminalId)} />
                </div>
            ) : null}
            <div>{terminalId && <QuayCraneDropdown terminalId={terminalId} />}</div>
        </LayoutHeader>
    );
};
