import { useAndonsStore } from '@/store';
import { QuayCranes } from '@/types';
import { naturalSort } from '@/utils/naturalSort';

export const useAndonsPerQuayCrane = (quayCranes: QuayCranes) => {
    const allAndons = useAndonsStore(state => state.andons);
    const andonsForQuayCranes = allAndons
        .filter(andon => quayCranes.map(({ quayCraneId }) => quayCraneId).includes(andon.cheName))
        .sort((a, b) => naturalSort(a.cheName, b.cheName));

    return andonsForQuayCranes;
};
