import { Marker } from 'react-map-gl/maplibre';

import {
    InaccurateSignalTruckMarker,
    StaleSignalTruckMarker,
    TruckMarker,
} from '@/components/atoms/TruckMarker/TruckMarker';
import { TruckMapShapes } from '@/constants/enums';
import { useTruckStatusPerQuayCrane } from '@/hooks/useTruckStatusPerQuayCrane';
import { useControlPageStore, useQuayCraneStore, useTruckPositionsStore } from '@/store';
import { calculateTruckColorForMap } from '@/utils/calculateTruckColorForMap';

export const TruckMarkers = ({
    quayCraneName,
    onTruckClicked,
    shape,
}: {
    quayCraneName: string;
    onTruckClicked: (truckId: string) => void;
    shape: TruckMapShapes;
}) => {
    const filteredTrucks = useTruckStatusPerQuayCrane(quayCraneName);
    const truckPositions = useTruckPositionsStore(state => state.truckPositions);
    const consideredCompletedTruckNamesForMajesticOnly = useQuayCraneStore(
        state => state.workQueuesPerQuayCrane[quayCraneName]?.consideredCompletedTruckNames,
    );
    const { userSelectedQuayCranes, userFilteredEquipmentOnMapPerQuayCrane } = useControlPageStore(
        state => ({
            userSelectedQuayCranes: state.userSelectedQuayCranes,
            userFilteredEquipmentOnMapPerQuayCrane: state.userFilteredEquipmentOnMapPerQuayCrane,
        }),
    );

    const trucksFilteredWithColor = Object.values(truckPositions)
        .filter(({ terminalTruckId }) => {
            return (
                filteredTrucks === undefined ||
                filteredTrucks.find(t => t.terminalTruckId === terminalTruckId)
            );
        })
        .map(t => {
            const status = filteredTrucks?.find(
                ({ terminalTruckId }) => terminalTruckId === t.terminalTruckId,
            );
            if (consideredCompletedTruckNamesForMajesticOnly && status) {
                status.workInstructionConsideredCompleted =
                    consideredCompletedTruckNamesForMajesticOnly.includes(status.terminalTruckId);
            }

            return {
                ...t,
                color: calculateTruckColorForMap(status),
            };
        });

    // User has selected quay cranes, but there are no trucks for this crane
    if (userSelectedQuayCranes.length > 0 && !filteredTrucks) {
        return null;
    }

    if (userFilteredEquipmentOnMapPerQuayCrane.find(qc => qc.quayCraneId === quayCraneName)) {
        // If the user has filtered the trucks on the map, we don't want to show the trucks for the quay crane
        return null;
    }

    return (
        <>
            {trucksFilteredWithColor.map((truck, index) => {
                return (
                    <Marker
                        key={index}
                        longitude={truck.longitude}
                        latitude={truck.latitude}
                        onClick={() => onTruckClicked(truck.terminalTruckId)}
                        color="transparent"
                        anchor="center"
                        style={{
                            width: 28,
                            height: 28,
                        }}
                    >
                        <div key={truck.terminalTruckId} className="relative">
                            <TruckMarker
                                shape={shape}
                                truckId={truck.terminalTruckId}
                                color={truck.color}
                            />
                            {truck.hasInaccurateSignal && (
                                <InaccurateSignalTruckMarker shape={shape} />
                            )}
                            {truck.hasStaleSignal && <StaleSignalTruckMarker shape={shape} />}
                        </div>
                    </Marker>
                );
            })}
        </>
    );
};
