import { Layout } from '@maersk-global/apmt-react-layout';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { Header } from '@/components/organisms/Header/Header';
import { NavigationRailContent } from '@/components/organisms/NavigationRailContent/NavigationRailContent';
import { useSubscribeHook } from '@/hooks/subscribeHook';
import { useTerminalSettings } from '@/hooks/terminalInfo/TerminalInfoProvider';
import { useAllQuayCranes } from '@/hooks/useAllQuayCranes';
import { useVersionCheckHook } from '@/hooks/useVersionCheckHook';
import { useTerminalStore } from '@/store/terminalStore';
import { isTest } from '@/test/helpers/isTest';

export default function MainLayout() {
    const navigate = useNavigate();
    const { versionHasBeenUpdated } = useVersionCheckHook();
    const { terminalId: terminalIdFromParams, quayCraneName: quayCraneNameFromParams } =
        useParams();

    const { setquayCraneName, setTerminalId } = useTerminalStore(
        state => ({
            setquayCraneName: state.setquayCraneName,
            setTerminalId: state.setTerminalId,
        }),
        shallow,
    );
    const terminalSettings = useTerminalSettings();
    const { error: errorAllQuayCranes, data: allQuayCranes } = useAllQuayCranes();
    const setAllQuayCranes = useTerminalStore(state => state.setAllQuayCranes);

    useSubscribeHook(terminalSettings.decodedGpsSignalStaleAfterMinutes);

    useEffect(() => {
        const terminalId = terminalIdFromParams ?? terminalSettings.terminalId;
        setTerminalId(terminalId);
    }, [terminalIdFromParams]);

    useEffect(() => {
        setquayCraneName(quayCraneNameFromParams ?? '');
    }, [quayCraneNameFromParams]);

    useEffect(() => {
        if (!versionHasBeenUpdated) {
            return;
        }

        navigate(0);
    }, [versionHasBeenUpdated]);

    useEffect(() => {
        if (allQuayCranes === undefined) {
            return;
        }
        setAllQuayCranes(allQuayCranes);
    }, [allQuayCranes]);

    if (errorAllQuayCranes && !isTest) {
        console.error('Error fetching quay cranes', errorAllQuayCranes);
    }

    return (
        <Layout
            icon={
                <div className="box-border flex size-16 flex-col items-center justify-center border-r border-gray-300">
                    {/* Deliberately empty until Bento */}
                </div>
            }
            header={<Header />}
            navigation={<NavigationRailContent />}
        >
            <Outlet />
        </Layout>
    );
}
