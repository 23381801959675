import { Marker } from 'react-map-gl/maplibre';

import {
    InaccurateSignalTruckMarker,
    StaleSignalTruckMarker,
    TruckMarker,
} from '@/components/atoms/TruckMarker/TruckMarker';
import { TruckMapColors, TruckMapShapes } from '@/constants/enums';
import { useTruckPositionsStore } from '@/store';

export const AllTruckMarkers = ({
    onTruckClicked,
    shape = TruckMapShapes.CIRCLE,
}: {
    onTruckClicked: (truckId: string) => void;
    shape?: TruckMapShapes;
}) => {
    const truckPositions = useTruckPositionsStore(state => state.truckPositions);

    return (
        <>
            {Object.values(truckPositions).map(truck => {
                return (
                    <Marker
                        key={truck.terminalTruckId}
                        longitude={truck.longitude}
                        latitude={truck.latitude}
                        onClick={() => onTruckClicked(truck.terminalTruckId)}
                        color="transparent"
                        anchor="center"
                        style={{
                            width: 28,
                            height: 28,
                        }}
                    >
                        <div key={truck.terminalTruckId} className="relative">
                            <TruckMarker
                                shape={shape}
                                truckId={truck.terminalTruckId}
                                color={TruckMapColors.BLUE}
                            />
                            {truck.hasInaccurateSignal && (
                                <InaccurateSignalTruckMarker shape={shape} />
                            )}
                            {truck.hasStaleSignal && <StaleSignalTruckMarker shape={shape} />}
                        </div>
                    </Marker>
                );
            })}
        </>
    );
};
