import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import {
    Button,
    Checkbox,
    Dialog,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogTitle,
    DialogTopSection,
    DialogTrigger,
} from '@maersk-global/apmt-react-components';
import { SvgCog } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EditMode, useMapStore } from '@/store/mapStore';

type EditStandByPositionDialogProps = {
    allQuayCranes: { quayCraneId: string }[];
    selectedQuayCraneIds: string[];
    setSelectedQuayCraneIds: (ids: string[]) => void;
    mapEditStartPlace: EditMode['startedFrom'];
    label?: string;
    onOpenChange?: (open: boolean) => void;
};

export const EditStandByPositionDialog = ({
    mapEditStartPlace,
    allQuayCranes,
    selectedQuayCraneIds,
    setSelectedQuayCraneIds,
    label = 'pages.editStandByPositionDialog.smallLabel',
    onOpenChange,
}: EditStandByPositionDialogProps) => {
    const { enableMapEditMode, disableMapEditMode } = useMapStore(store => ({
        enableMapEditMode: store.enableEditMode,
        disableMapEditMode: store.disableEditMode,
    }));
    const { t } = useTranslation();
    const { user } = useAuth();

    const nav = useNavigate();
    if (user.isAdmin || user.isNormal) {
        return (
            <Dialog onOpenChange={onOpenChange}>
                <DialogTrigger asChild>
                    <Button
                        fit="small"
                        variant="primaryOutlined"
                        icon={<SvgCog />}
                        className="flex flex-row gap-2"
                    >
                        <span>{t(label)}</span>
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTopSection>
                        <DialogTitle>Configure standby positions</DialogTitle>
                        <div>
                            Manage standby positions for...
                            <div className="flex flex-col">
                                {[...allQuayCranes]
                                    .sort((a, b) => a.quayCraneId.localeCompare(b.quayCraneId))
                                    .map(({ quayCraneId }) => {
                                        return (
                                            <div key={quayCraneId} className="self-start">
                                                <Checkbox
                                                    checked={selectedQuayCraneIds.includes(
                                                        quayCraneId,
                                                    )}
                                                    onCheckedChange={checked => {
                                                        if (checked) {
                                                            setSelectedQuayCraneIds([
                                                                ...selectedQuayCraneIds,
                                                                quayCraneId,
                                                            ]);
                                                        } else {
                                                            setSelectedQuayCraneIds(
                                                                selectedQuayCraneIds.filter(
                                                                    id => id !== quayCraneId,
                                                                ),
                                                            );
                                                        }
                                                    }}
                                                    labelPosition="right"
                                                    label={quayCraneId}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </DialogTopSection>
                    <DialogFooter>
                        <DialogCloseButton
                            onClick={() => {
                                setSelectedQuayCraneIds([]);
                                return disableMapEditMode();
                            }}
                        >
                            Cancel
                        </DialogCloseButton>
                        <Button
                            disabled={selectedQuayCraneIds.length === 0}
                            onClick={() =>
                                enableMapEditMode(
                                    {
                                        quayCraneNames: selectedQuayCraneIds,
                                        startedFrom: mapEditStartPlace,
                                    },
                                    nav,
                                )
                            }
                            fit="small"
                        >
                            Position on map
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        );
    } else {
        return null;
    }
};
